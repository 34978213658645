<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-03-08 13:07:53
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-07-03 18:02:44
 * @FilePath: \official_website\src\mobile\pages\about\About.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--  -->
<template>
  <div id="about">
    <Header></Header>
    <div class="banner"></div>
    <div class="company-profile wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
      <div class="title">公司简介</div>
      <div class="company-img">
        <img src="../../../assets/image/about/synopsis-img.jpg" alt="" />
      </div>
      <div class="introduce">
        <p class="first">
          湖南品信生物工程有限公司于2013年由中南大学教授与留学归国博士团队共同创立，旨在为医疗领域提供人工智能精准诊断技术，
          已发展为集<strong>研发、生产、销售、服务于一体</strong>的创新型高新技术企业。
        </p>

        <p class="second">
          以<strong>医疗器械和生物制剂</strong>为主营业务，聚焦细胞形态学智能检测，提供制片-染色-数字化扫描-人工智能分析-大数据远程诊断于一体的全流程医学诊断解决方案，
          涵盖宫颈癌、肺癌、口腔癌等多种肿瘤的早期筛查。目前已在全国大型三甲医院、第三方医学实验室大量投入使用。品信人秉承“品为先，信至上”的理念，携手医疗行业专家、同仁和社会各界人士，
          全力加快人工智能技术在病理检测领域的应用研究与推广，推动病理诊断水平的提高与诊断效率的提升，为成就健康中国而奋斗！
        </p>
      </div>
    </div>
    <div class="corporate-culture">
      <div class="title wow animate__animated animate__fadeInUp">
        <div>践行企业文化</div>
        <div>共创行业佳话</div>
      </div>
      <div class="culture-box">
        <van-swipe
          :autoplay="3000"
          indicator-color="#0b847b"
          width="100%"
          height="100%"
        >
          <van-swipe-item v-for="item in cultureList" :key="item.id">
            <img :src="item.image" />
            <div class="mask">
              <div class="culture-contnet wow animate__animated animate__fadeIn">
                <div class="theme">{{ item.title }}</div>
                <div class="detail">
                  {{ item.describe }}
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <div class="cover-box">
      <div
        class="title wow animate__animated animate__fadeInUp"
        data-wow-delay="0.5s"
      >
        业务覆盖全国20+省市 300+医院
      </div>
    </div>
    <div class="development-history">
      <div class="left">
        <div class="course-box">
          <div class="course-img">
            <img src="@/assets/image/about/history-img.jpg" alt="" />
          </div>
          <div class="course-content">
            <div class="area">
              <div class="times">{{ selectedValue }}</div>
              <div class="text wow animate__animated animate__fadeInUp">
                {{ selectedText }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <scroll-picker
          v-model="selectedValue"
          :options="options"
          @input="handleChange"
        />
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import api from "../../../api/api";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
import { ScrollPicker, ScrollPickerGroup } from "vue-scroll-picker";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
    ScrollPicker,
    ScrollPickerGroup,
  },
  data() {
    return {
      cultureList: [
        {
          id: 1,
          image: require("../../../assets/image/about/culture-1.jpg"),
          title: "愿景",
          describe: "成为一家能不断发现和满足客户需求，充满活力的公司",
        },
        {
          id: 2,
          image: require("../../../assets/image/about/culture-2.jpg"),
          title: "使命",
          describe:
            "以创新科技驱动病理诊断，去探索生命中更多地未知，从而创造更加美好的健康生活",
        },
        {
          id: 3,
          image: require("../../../assets/image/about/culture-3.jpg"),
          title: "价值观",
          describe: "永远追求真相、本质和原理持续长期付出，不断提升价值",
        },
      ],
      selectedValue: "2022-2023年",
      selectedText:
        "加快布局产品多元化战略，快速实现以人工智能为核心的细胞形态学一体化解决方案，布局血液细胞形态学AI分析",
      courseList: [
        {
          name: "2022-2023年",
          value:
            "加快布局产品多元化战略，快速实现以人工智能为核心的细胞形态学一体化解决方案，布局血液细胞形态学AI分析",
        },
        {
          name: "2019-2021年",
          value:
            "与首都医科大学附属北京胸科医院、首都医科大学附属朝阳医院、西京医院、河北省肿瘤医院、湖南省人民医院等全国300余家医院达成深度合作",
        },
        {
          name: "2017-2018年",
          value: "三代产品鹰眼、四代产品吉米娜获批CFDA二类证，产品正式上市",
        },
        {
          name: "2013-2016年",
          value:
            "潜心产品研发，专注人工智能技术在医学诊断领域的应用研究，完成上百万例宫颈癌检测和大数据样本收集",
        },
      ],
      options: ["2022-2023年", "2019-2021年", "2017-2018年", "2013-2016年"],
    };
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      new this.$wow.WOW({
        boxClass: "wow", // 动画元件css类（默认为wow）
        animateClass: "animate__animated", //动画css类（默认为animated）
        offset: 0, //到元素距离触发动画（当默认为0）
        mobile: true, //在移动设备上触发动画（默认为true）
        live: true, //对异步加载的内容进行操作（默认为true）
      }).init();
    });
  },
  watch: {},
  methods: {
    handleChange(value) {
      this.selectedValue = value;
      let currentItem = this.courseList.filter((item) => {
        return item.name == value;
      });
      if (currentItem.length != 0) {
        this.selectedText = currentItem[0].value;
      }
    },
  },
};
</script>
    
<style lang="scss">
#about {
  padding-top: 3.75rem;
  .banner {
    width: 100%;
    height: 12rem;
    background-image: url("../../../assets/image/about/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    animation-name: scaleDraw; /*关键帧名称*/
    animation-timing-function: ease; /*动画的速度曲线*/
    animation-duration: 2s; /*动画所花费的时间*/
  }
  .company-profile {
    padding: 2rem 1.25rem;
    .title {
      text-align: center;
      margin-bottom: 1rem;
      font-family: "OPlusSans3-Bold";
      font-size: 1.5rem;
    }
    .company-img {
      width: 100%;
      margin-bottom: 1rem;
      img {
        width: 100%;
      }
    }
    .introduce {
      p {
        font-size: 1rem;
        font-family: "OPlusSans3-Regular";
        line-height: 2.5rem;
        strong {
          font-weight: 600;
        }
      }
      .first {
        margin-bottom: 1rem;
      }
      .second {
      }
    }
  }
  .corporate-culture {
    padding: 2rem 1.25rem;
    .title {
      margin-bottom: 1rem;
      div {
        font-size: 1.5rem;
        font-weight: 600;
        font-family: "SourceHanSansSC-Medium";
        text-align: center;
        &:first-child {
          margin-bottom: 0.5rem;
        }
      }
    }
    .culture-box {
      width: 100%;
      height: 12rem;
      .van-swipe__track {
        width: 100%;
        height: 100%;
        .van-swipe-item {
          position: relative;
          height: 12rem;
          img {
            width: 100%;
            height: 12rem;
            border-radius: 0.8rem;
          }
          .mask {
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 50%;
            padding: 0.5rem 1rem;
            background-color: rgba($color: #000, $alpha: 0.2);
            border-radius: 0 0 0.8rem 0.8rem;
            .culture-contnet {
              color: #fff;
              .theme {
                font-size: 1rem;
                font-family: "SourceHanSansSC-Medium";
                margin-bottom: 0.5rem;
              }
              .detail {
                font-size: 0.8rem;
                font-family: "OPlusSans3-Regular";
              }
            }
          }
        }
      }
    }
  }
  .cover-box {
    width: 100%;
    height: 8rem;
    margin-bottom: 2rem;
    background-image: url("../../../assets/image/about/distribution.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    .title {
      padding-top: 2rem;
      color: #fff;
      font-size: 1.25rem;
      text-align: center;
      font-family: "SourceHanSansSC-Medium";
    }
  }
  .development-history {
    padding: 2rem 1.25rem;
    display: flex;
    height: 22.5rem;
    border-radius: 0.8rem;
    .left {
      width: 70%;
      background-image: url("../../../assets/image/about/history-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      border-radius: 0.8rem 0 0 0.8rem;
      .course-box {
        .course-img {
          img {
            width: 100%;
            border-radius: 0.8rem 0 0 0;
          }
        }
      }
      .course-content {
        padding: 0 0.5rem;
        .area {
          .times {
            font-size: 1.2rem;
            font-family: "SourceHanSansSC-Medium";
            margin-bottom: 0.5rem;
          }
          .text {
            font-size: 0.9rem;
            font-family: "OPlusSans3-Regular";
            line-height: 1.5rem;
          }
        }
      }
    }
    .right {
      width: 30%;
      background-color: #406aa1;
      border-radius: 0 0.8rem 0.8rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .vue-scroll-picker {
        width: 80%;
        height: 50%;
        margin: 50% auto;
        .vue-scroll-picker-item {
          height: 4em;
          line-height: 4em;
          color: #fff;
          font-size: 0.8rem;
          font-family: "SourceHanSansSC-Medium";
        }
        .vue-scroll-picker-layer .top {
          height: calc(50% - 2em);
          background: linear-gradient(
            180deg,
            #406aa1 10%,
            rgba(64, 108, 159, 0.7)
          );
        }
        .vue-scroll-picker-layer .bottom {
          height: calc(50% - 2em);
          background: linear-gradient(
            0deg,
            #406aa1 10%,
            rgba(64, 108, 159, 0.7)
          );
        }
      }
    }
  }
  .footer {
    background: #f5f5f5;
  }
}
</style>